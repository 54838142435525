#br-controls {
    position: absolute;
    bottom: 20px;
    right: 10px;
    width: 20%;

    padding: 10px;

    background-color: var(--creamery);
    color: var(--creamery-font);
    
    border: 2px solid var(--arch-black);
    z-index: 2;
}

#br-controls h3 {
    margin-top: 0px;
    margin-bottom: 0.5em;
    text-align: center;
}
.scale {
    width: 100%;
    display: grid;
    grid-template-columns: 12% 1fr 12%;
}
#scale {
    width: 100%;
    height: 2em;
}
.scale input {
    margin: 2px 4px;
}
.two-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.layer-control-button {
    user-select: none;
    cursor: pointer;
}

#expand-legend {
    position: absolute;
    float: right;
    font-size: 1.3em;
    font-weight: bold;
    padding: 0.4rem 1rem;
    top: 0px;
    right: 0px;
    cursor: pointer;
}
#legend-collapse-title {
    display: none;
    float: left;
}