#sidebar {
    position: absolute;
    top: 13px;
    left: 52px;
    width: 25%;

    z-index: 2;
    pointer-events: none;
}
.sidebar-box {
    background-color: var(--creamery);
    color: var(--creamery-font);
    border-radius: 5px;
    padding: 10px;
    margin: 10px;

    width: 100%;
    position: relative;
    pointer-events: auto;
}
.sidebar-box-contents {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
img {
    width: 90%;
    align-self: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
#version-container {
    float: inline-end;
    display: flex;
}

.hidden {
    display: none;
}

.tiff-select {
    pointer-events: auto;
    white-space: pre;
    padding: 1%;
    margin: 1%;
    cursor: pointer;
    display: block;
}
.closed-dropdown {
    display: none;
    pointer-events: none;
}