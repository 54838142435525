* {
    box-sizing: border-box;
}
body {
    margin: 0px;
}

.container {
    width: 100%;
    height: 100vh;

    display: grid;
    grid-template-columns: 1fr;
}
#map {
    z-index: 1;
    width: 100%;
    height: 100%;
}

.fullscreen-button {
    background-color: var(--chapel-bell-white);
    padding: 4px;
    font-weight: 600;
    cursor: pointer;
}
.fullscreen {
    grid-template-columns: 0% 100%;
}
.opacity-slider {
    width: 100%;
}